.main {
  width: 100%;
}

.new_button_container {
  background-color: #fff;
}

.fixed {
  position: fixed;
  top:0;
  left: 0;
  height: 140px;
  width: 100vw;
  z-index: 100;
  background-color: #fff;
}

.new_button_wrapper {
  margin-left: 2rem;
}

.new_button {
  padding: 1rem 2rem;
  background-color: #007bff;
  color: white;
  border: none;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.title {
  margin-bottom: 0;
}

.new_button:hover {
  background-color: #0056b3;
}

.container {
  margin-top: 140px;
}

.form_container {
  background-color: #eee;
  padding: 1rem 2rem;
  margin: 2rem 3rem;
  border-radius: 1.2rem;
  display: flex;
  items-center: center;
  justify-content: space-between;
  cursor: pointer;
}
.short_form_container {
  background-color: #eee;
  padding: 1rem 1rem;
  margin: 2rem 3rem;
  border-radius: 1.2rem;
  display: flex;
  items-center: center;
  justify-content: space-between;
  cursor: pointer;
}
.form_left {
  display: flex;
  align-items: center;
  width: 60%;
}
.short_form_left {
  display: flex;
  align-items: center;
  max-width: 90%;
}
.form_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.form_name_wrapper {
  width: 100%;
}
.form_name {
  font-size: 0.8rem;
  /* font-weight: bold; */
  margin-left: 1rem;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.detail_form_name {
  font-size: 0.8rem;
  /* font-weight: bold; */
  margin-left: 1rem;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.delete_icon {
  color: #E95B6B;
  font-size: 1.4rem;
}
.form_description {
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}
.form_description_comp1 {
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #ff007b;
}
.form_description_comp2 {
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #00dd3b;
}
.form_description_comp3 {
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #007bff;
}
.form_description_comp4 {
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #ff7b00;
}

.buttons_container {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 0 2rem 1rem 0;
}
/* Form.module.css */
.formWrapper {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.section {
  margin-bottom: 20px;
}

.sectionHeader {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.inputField {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.textAreaField {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 30px;
}

.buttonBack {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #aaa;
  color: white;
  margin-right: 2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buttonSubmit {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #0056b3;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #003d82;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.text-center {
  text-align: center;
}

.main {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark-gray-text {
  color: #666;
}

/* ドロップダウンの基本スタイル */
.dropdown {
  position: relative;
  display: inline-block;
  font-family: 'Arial', sans-serif;
}

/* ドロップダウンボタンのスタイル */
.dropdown-button {
  padding: 10px 25px 5px 25px;
  background-color: white;
  border: none;
  border-bottom: 1px solid #333;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  transition: background-color 0.3s ease; /* 背景色のアニメーション */
  display: flex;
  align-items: center;
}

.dropdown-button:hover {
  background-color: #eee; /* ホバー時の色 */
}

/* ドロップダウンコンテンツ（メニュー項目）のスタイル */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
  border-radius: 5px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

/* ドロップダウン項目のスタイル */
.dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #ddd; /* ホバー時の背景色 */
}

/* ドロップダウンが開いている時のスタイル */
.dropdown:hover .dropdown-content {
  display: block;
  opacity: 1;
}

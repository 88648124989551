.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明の背景 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* ソフトな影 */
  width: 90%;
  max-width: 500px; /* 適切な最大幅 */
}

.closeButton {
  float: right;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
}

.back_button {
    position: fixed;
    top: 10px;
    left: 10px;
    background-size: 100% 100%;
    cursor: pointer;
    font-size: 12px;
    color: rgb(14, 165, 233);
    z-index: 100;
}

.title {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    color: rgb(14, 14, 14);
    z-index: 100;
}